import React from 'react';

import { DefaultFcProps } from '../../../../common';

import './header.scss';
import { Nav } from './components/nav';

export type HeaderProps = DefaultFcProps;

export const Header: React.FC<HeaderProps> = () => {
  return (
    <div className="app-header flex justify-between">
      <Nav />
    </div>
  );
}
