import { ButtonCopyToClipboard } from "../button-copy-to-clipboard"
import { formatAddress } from "utils"

export const WalletAddress = ({
  address
}: any) => {
  return (
    <div className="w-full flex justify-between items-center">
      <span>{formatAddress(
        address
      )}</span>
      <ButtonCopyToClipboard val={address} />
    </div>
  )
}