import { FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { DefaultFcProps, RedeemRequestItem } from 'common';
import format from 'format-number';
import { Button } from '@mui/material';
import { WalletAddress } from '../../wallet-address';

const defaultFormat = format()

const style = {
  color: '#748099',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 533,
  bgcolor: 'background.paper',
  p: '32px 40px',
  boxShadow: '0px 23px 50px rgba(10, 31, 67, 0.2), 0px 12px 7px rgba(10, 31, 67, 0.05)',
  borderRadius: '8px'
};

type ModalRejectRedeemRequestProps = {
  disabled: boolean,
  data: RedeemRequestItem[],
  open: boolean,
  onClose: () => void,
  onAction?: (action: string) => void,
} & DefaultFcProps;

export const ModalRejectRedeemRequest: FC<ModalRejectRedeemRequestProps> = ({
  disabled,
  data,
  open,
  onClose,
  onAction
}) => {
  const totalPoints = useMemo(() => data.reduce((sum, item) => sum + item.point, 0), [data])

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h2 className='text-[32px] font-bold'>
          Reject
        </h2>
        <h3 className='text-[18px]'>
          Reject redeem requests
        </h3>
        <div className='mt-12 max-h-[300px] overflow-y-auto'>
          <table className='w-full bg-[#F7F7F8]'>
            <thead className='bg-[#E5EDF5]'>
              <tr className='h-[37px]'>
                <th className='text-left'>Wallet Address</th>
                <th className='text-right'>Amount</th>
              </tr>
            </thead>
            <tbody>
              {data.map((i) => (
                <tr key={i._id} className="py-3 h-[32px]">
                  <td className='text-left'>
                    <WalletAddress address={i.userAddress} />
                  </td>
                  <td className='text-right'>{defaultFormat(i.point)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='flex justify-between items-baseline mt-6'>
          <div>Total</div>
          <div className='flex gap-2 items-baseline'><span className='text-[#3D8EE0] font-bold text-[32px]'>{defaultFormat(totalPoints)}</span><span className='text-gray font-bold'>OKG</span></div>
        </div>

        <div className='flex gap-3 justify-end mt-6'>
          <Button
            disabled={disabled}
            variant='contained'
            color="primary"
            onClick={() => onAction && onAction('confirm')}
          >Confirm</Button>
          <Button
            disabled={disabled}
            variant='contained'
            color="secondary"
            onClick={() => onAction && onAction('cancel')}
          >Cancel</Button>
        </div>
      </Box>
    </Modal>
  )
}
