import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { compose } from 'ramda';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DefaultLayout } from 'layouts';
import { DefaultFcProps, lazyLoadThenCreateElement } from 'common';
import { withReactQuery, } from './helpers';
import { withMuiTheme } from 'mui-theme';
import { routers, withRouter } from '../routes';
import { withRedux } from 'redux-reducers';

import { Loading } from 'shared-components';

import './app.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';

const Home = lazyLoadThenCreateElement(() => import('pages'), 'Home');
const Login = lazyLoadThenCreateElement(() => import('pages'), 'Login');
const Logout = lazyLoadThenCreateElement(() => import('pages'), 'Logout');

type AppProps = DefaultFcProps;

const BaseApp: React.FC<AppProps> = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path='/login' element={Login} />
          <Route path='/logout' element={Logout} />
          <Route path='/' element={Home} />
          <Route path='/*' element={<DefaultLayout />}>
            {routers.map((route, index) => (<Route key={index} {...route} />))}
          </Route>
        </Routes>
      </Suspense>
    </LocalizationProvider>
  );
}

export const App = compose(
  withRouter,
  withMuiTheme,
  withReactQuery,
  withRedux
)(BaseApp);
