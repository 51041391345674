import { lazyLoadThenCreateElement } from 'common';

const WithdrawKab = lazyLoadThenCreateElement(() => import('pages'), 'WithdrawKab');
const WithdrawOkg = lazyLoadThenCreateElement(() => import('pages'), 'WithdrawOkg');
const Daily = lazyLoadThenCreateElement(() => import('pages'), 'Daily');

export const routers = [
  { path: 'withdraw/kab/:tab', element: WithdrawKab },
  { path: 'withdraw/okg/:tab', element: WithdrawOkg },
  { path: 'daily/:token/:tab', element: Daily },
];