import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import {
  erc20Abi,
  erc721Abi,
  disperseMultiSenderAbi,
} from './abis';

export const web3 = new Web3(window.ethereum);
export const MAX_INT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const erc20Contract = (address: string) => new web3.eth.Contract(erc20Abi as AbiItem[], address);
export const erc721Contract = (address: string) => new web3.eth.Contract(erc721Abi as AbiItem[], address);

export const disperseMultiSenderContract = (address: string) => new web3.eth.Contract(disperseMultiSenderAbi as AbiItem[], address)
