import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux-reducers';

export interface UserState {
  isLoggedIn: boolean;
  address: string;
}

const initialState: UserState = {
  isLoggedIn: false,
  address: '',
}

export const userSliceName = "user";

const userSlice = createSlice({
  name: userSliceName,
  initialState,
  reducers: {
    signIn: (state, action) => {
      const { payload } = action
      state = { ...state, ...payload, isLoggedIn: true };
      return state;
    },
    signOut: (state) => {
      return { ...initialState, isLoggedIn: false }
    },
  },
});

export const {
  reducer: userReducer
} = userSlice;

// actions
export const {
  signIn,
  signOut
} = userSlice.actions;

// selector
export const selectIsLoggedIn = (state: RootState) => state[userSliceName].isLoggedIn;
export const selectAddress = (state: RootState) => state[userSliceName].address;
