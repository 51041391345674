import clsx from 'clsx';
import React from 'react';
import { DefaultFcProps } from '../../../../common';

import classes from './footer.module.scss';

export type FooterProps = DefaultFcProps;

export const Footer: React.FC<FooterProps> = () => {
  return (
    <footer className="app-footer mt-12">
      <div className={clsx(classes.footerCopyRight, 'flex items-center justify-center')}>
        Copyright © 2022 Spores
      </div>
    </footer>
  );
}
