import React from 'react';
import clsx from 'clsx';
import { DefaultFcProps } from 'common';

import classes from './panel.module.scss'

export type PanelProps = DefaultFcProps;

export const Panel: React.FC<PanelProps> = ({
  className,
  children
}) => {
  return (
    <div className={clsx(classes.panelContainer, className)}>
      {children}
    </div>
  );
}