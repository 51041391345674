import type { } from '@mui/x-date-pickers/themeAugmentation';
import { createTheme } from "@mui/material";
import { alpha } from '@mui/material/styles';

const globalTheme = createTheme({
  palette: {
    primary: {
      main: '#3D8EE0',
    },
    info: {
      main: '#C7C7C7',
    },
    error: {
      main: '#F83C3C',
    },
    secondary: {
      main: '#EBECF0'
    }
  },
});


export const muiTheme = createTheme({
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'none'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 42,
          color: '#7A7F9A',
          fontSize: '16px'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          height: 44,
          border: `1px solid ${globalTheme.palette.info.main}`,
          boxShadow: "none",
          transition: 'none'
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          height: 'unset'
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            boxShadow: 'none'
          }
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderColor: globalTheme.palette.info.main,
            color: '#6D6E78',
            '&:hover': {
              borderColor: alpha(globalTheme.palette.info.main, 0.16),
            },
            '&:active': {
              borderColor: alpha(globalTheme.palette.info.main, 0.32),
            },
          }
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            borderColor: globalTheme.palette.error.main,
            color: globalTheme.palette.error.main,
            '&:hover': {
              borderColor: alpha(globalTheme.palette.error.main, 0.16),
            },
            '&:active': {
              borderColor: alpha(globalTheme.palette.error.main, 0.32),
            },
          }
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: globalTheme.palette.secondary.main,
            color: '#252E58',
            '&:hover': {
              backgroundColor: alpha(globalTheme.palette.secondary.main, 0.16),
            },
            '&:active': {
              backgroundColor: alpha(globalTheme.palette.secondary.main, 0.32),
            },
          }
        }
      ],
      styleOverrides: {
        root: {
          minWidth: 100,
          height: 44,
          padding: '10px 16px'
        }
      }
    },

    //
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          border: 'none',
          backgroundColor: '#fff',
        },
      },
    },
    MuiCalendarOrClockPicker: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '0px 0px 1px rgb(12 26 75 / 10%), 0px 4px 20px -2px rgb(50 50 71 / 8%)'
        },
      },
    }
  }
});