import { store, signOut } from "redux-reducers";

export const configurableFetch = (
  // eslint-disable-next-line
  input: RequestInfo | URL,
  // eslint-disable-next-line
  init?: RequestInit
) =>
  fetch(input, init)
    .then((response: any) => {
      if (response.status === 401) {
        store.dispatch(
          signOut()
        );

        setTimeout(() => {
          document.location.href = '/';
        }, 0);

        return Promise.reject(response);
      }
      return response;
    });
