import { Container } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { DefaultFcProps, useClassName, useLoggedInGuard } from 'common';
import { Footer, Header } from './components';

import './default-layout.scss';

export type DefaultLayoutProps = DefaultFcProps;

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  className,
}) => {
  useLoggedInGuard()

  const combinedClassName = useClassName(["app-wrapper", className]);

  return (
    <div className={combinedClassName}>
      <Header />
      <Container className='app-content' maxWidth="xl">
        <Outlet />
      </Container>
      <Footer />
    </div>
  );
}
