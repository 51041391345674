import React from 'react';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useClassName } from 'common';
import classes from './nav.module.scss';
import { UserInformation } from '../user-information';

const activeStyle = {
  textDecoration: 'none',
  color: '#CFB675',
};

const normalStyle = {
  textDecoration: 'none',
  color: '#595973',
};

export type NavProps = {
  className?: string;
};

export const Nav: React.FC<NavProps> = () => {
  const logoClassName = useClassName([classes.textLogo, 'text-overflow mr-6']);

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <div className="flex items-center justify-between">
          <Link className={logoClassName} to="/">
            <img src="/logo.png" height={52} alt="Okg logo" />
          </Link>
          <div className="flex justify-end gap-6">
            <div className="flex items-center font-bold gap-6">
              <NavLink
                className="mr-6"
                to="/withdraw/kab/pending"
                style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
              >
                Withdraw KAB
              </NavLink>
              <NavLink
                className="mr-6"
                to="/withdraw/okg/pending"
                style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
              >
                Withdraw OKG
              </NavLink>
              <NavLink
                className="mr-6"
                to="/daily/okg/pending"
                style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
              >
                Daily OKG
              </NavLink>
            </div>
            <UserInformation />
          </div>
        </div>
      </Container>
    </AppBar>
  );
};
