// @ts-nocheck
import { getApiService } from "../helpers";

/*
    OKG marketplace API generated
    The Okeenga marketplace API description
    version: 0.0.1

*/

export class Configuration {
	basePath? = '';
	fetchMethod = fetch;
	headers?: any = {};
    getHeaders: any = () => { return {} };
    responseHandler: any = null;
    errorHandler: any = null;

	constructor(config: Configuration | any) {
	    if (config) {
	        if (config.basePath) {
                this.basePath = config.basePath;
            }
            if (config.fetchMethod) {
                this.fetchMethod = config.fetchMethod;
            }
            if (config.headers) {
                this.headers = config.headers;
            }
            if (config.getHeaders) {
                this.getHeaders = config.getHeaders;
            }
            if (config.responseHandler) {
                this.responseHandler = config.responseHandler;
            }
            if (config.errorHandler) {
                this.errorHandler = config.errorHandler;
            }
	    }
	}
}

const setAdditionalParams = (params = [], additionalParams = {}) => {
    Object.keys(additionalParams).forEach(key => {
        if(additionalParams[key]) {
            params.append(key, additionalParams[key]);
        }
    });
};

export class LoginDTO {
	addr?: string;
	signedStr?: string;

	constructor(obj: LoginDTO) {
        this.addr = obj.addr;
        this.signedStr = obj.signedStr;
	}
}

export class RegisterDto {
	addr?: string;
	signedStr?: string;
	email?: string;
	username?: string;
	password?: string;
	refCode?: string;

	constructor(obj: RegisterDto) {
        this.addr = obj.addr;
        this.signedStr = obj.signedStr;
        this.email = obj.email;
        this.username = obj.username;
        this.password = obj.password;
        this.refCode = obj.refCode;
	}
}

export class ForgotPasswordDto {
	email?: string;
	operatingSystem?: string;
	browserName?: string;

	constructor(obj: ForgotPasswordDto) {
        this.email = obj.email;
        this.operatingSystem = obj.operatingSystem;
        this.browserName = obj.browserName;
	}
}

export class ResetPasswordDto {
	newPassword?: string;
	email?: string;
	code?: string;

	constructor(obj: ResetPasswordDto) {
        this.newPassword = obj.newPassword;
        this.email = obj.email;
        this.code = obj.code;
	}
}

export class ChangePasswordDto {
	newPassword?: string;
	currentPassword?: string;

	constructor(obj: ChangePasswordDto) {
        this.newPassword = obj.newPassword;
        this.currentPassword = obj.currentPassword;
	}
}

export class OpenCocoonDTO {
	contract?: string;
	tokenIds?: string[];

	constructor(obj: OpenCocoonDTO) {
        this.contract = obj.contract;
        this.tokenIds = obj.tokenIds;
	}
}

export class SellInput {
	price?: number;

	constructor(obj: SellInput) {
        this.price = obj.price;
	}
}

export class EstimateBreedingDto {
	firstHeroTokenId?: string;
	secondHeroTokenId?: string;
	contract?: string;

	constructor(obj: EstimateBreedingDto) {
        this.firstHeroTokenId = obj.firstHeroTokenId;
        this.secondHeroTokenId = obj.secondHeroTokenId;
        this.contract = obj.contract;
	}
}

export class UpdateUserInfoDTO {
	name?: string;
	description?: string;
	email?: string;
	image?: string;

	constructor(obj: UpdateUserInfoDTO) {
        this.name = obj.name;
        this.description = obj.description;
        this.email = obj.email;
        this.image = obj.image;
	}
}

export class CreateScholarSubAccDto {
	subAccName?: string;

	constructor(obj: CreateScholarSubAccDto) {
        this.subAccName = obj.subAccName;
	}
}

export class CreateScholarshipDto {
	username?: string;
	scholarWeb3Address?: string;
	heroTokenIds?: string[];
	scholarshipName?: string;
	ratio?: number;
	start?: any;

	constructor(obj: CreateScholarshipDto) {
        this.username = obj.username;
        this.scholarWeb3Address = obj.scholarWeb3Address;
        this.heroTokenIds = obj.heroTokenIds;
        this.scholarshipName = obj.scholarshipName;
        this.ratio = obj.ratio;
        this.start = obj.start;
	}
}

export class ClaimScholarshipDto {
	scholarshipIds?: string[];

	constructor(obj: ClaimScholarshipDto) {
        this.scholarshipIds = obj.scholarshipIds;
	}
}

export class PayoutScholarshipDto {
	scholarshipIds?: string[];

	constructor(obj: PayoutScholarshipDto) {
        this.scholarshipIds = obj.scholarshipIds;
	}
}

export class UpdateScholarshipDto {
	username?: string;
	scholarWeb3Address?: string;
	heroTokenIds?: string[];
	scholarshipName?: string;
	ratio?: number;
	start?: any;

	constructor(obj: UpdateScholarshipDto) {
        this.username = obj.username;
        this.scholarWeb3Address = obj.scholarWeb3Address;
        this.heroTokenIds = obj.heroTokenIds;
        this.scholarshipName = obj.scholarshipName;
        this.ratio = obj.ratio;
        this.start = obj.start;
	}
}

export class TransferAssetToSubAccInput {
	username?: string;
	gKab?: number;

	constructor(obj: TransferAssetToSubAccInput) {
        this.username = obj.username;
        this.gKab = obj.gKab;
	}
}

export class WithdrawalRequestDto {
	amount?: number;

	constructor(obj: WithdrawalRequestDto) {
        this.amount = obj.amount;
	}
}

export class DepositRequestDto {
	amount?: number;

	constructor(obj: DepositRequestDto) {
        this.amount = obj.amount;
	}
}

export class ConfirmDepositRequestDto {
	transactionHash?: string;

	constructor(obj: ConfirmDepositRequestDto) {
        this.transactionHash = obj.transactionHash;
	}
}

export class CheckInManyDto {
	addresses?: string[];

	constructor(obj: CheckInManyDto) {
        this.addresses = obj.addresses;
	}
}

export class MethodOptions {
	headers?: any = {};
	returnResponse?: boolean = false;

	constructor(options: MethodOptions) {
		if (options.headers) {
			this.headers = options.headers;
		}
		if (options.returnResponse) {
			this.returnResponse = options.returnResponse;
		}
	}
}

export class ApiAuthNonceAddrGetArgs {
	addr?: string;

	constructor(args: ApiAuthNonceAddrGetArgs) {
        this.addr = args.addr;
	}
}

export class ApiAdminAuthNonceAddrGetArgs {
	addr?: string;

	constructor(args: ApiAdminAuthNonceAddrGetArgs) {
        this.addr = args.addr;
	}
}

export class ApiCocoonsContractTokenIdGetArgs {
	contract?: string;
	tokenId?: string;

	constructor(args: ApiCocoonsContractTokenIdGetArgs) {
        this.contract = args.contract;
        this.tokenId = args.tokenId;
	}
}

export class ApiCocoonsGetArgs {
	owner?: string;
	limit?: number;
	offset?: number;
	cocoonTypes?: string;
	orderBy?: string;
	desc?: any;
	saleType?: string;

	constructor(args: ApiCocoonsGetArgs) {
        this.owner = args.owner;
        this.limit = args.limit;
        this.offset = args.offset;
        this.cocoonTypes = args.cocoonTypes;
        this.orderBy = args.orderBy;
        this.desc = args.desc;
        this.saleType = args.saleType;
	}
}

export class ApiCocoonsContractTokenIdRefreshPostArgs {
	contract?: string;
	tokenId?: string;

	constructor(args: ApiCocoonsContractTokenIdRefreshPostArgs) {
        this.contract = args.contract;
        this.tokenId = args.tokenId;
	}
}

export class ApiCocoonsContractTokenIdSellPostArgs {
	contract?: string;
	tokenId?: string;

	constructor(args: ApiCocoonsContractTokenIdSellPostArgs) {
        this.contract = args.contract;
        this.tokenId = args.tokenId;
	}
}

export class ApiCocoonsSalesSaleIdBuyPostArgs {
	saleId?: string;

	constructor(args: ApiCocoonsSalesSaleIdBuyPostArgs) {
        this.saleId = args.saleId;
	}
}

export class ApiCocoonsSalesSaleIdDeleteArgs {
	saleId?: string;

	constructor(args: ApiCocoonsSalesSaleIdDeleteArgs) {
        this.saleId = args.saleId;
	}
}

export class ApiCocoonsContractTokenIdSaleGetArgs {
	contract?: string;
	tokenId?: string;

	constructor(args: ApiCocoonsContractTokenIdSaleGetArgs) {
        this.contract = args.contract;
        this.tokenId = args.tokenId;
	}
}

export class ApiCocoonsContractSyncUserAddressPostArgs {
	address?: string;

	constructor(args: ApiCocoonsContractSyncUserAddressPostArgs) {
        this.address = args.address;
	}
}

export class ApiHeroesBreedEstimateGetArgs {
	firstHeroTokenId?: string;
	secondHeroTokenId?: string;
	contract?: string;

	constructor(args: ApiHeroesBreedEstimateGetArgs) {
        this.firstHeroTokenId = args.firstHeroTokenId;
        this.secondHeroTokenId = args.secondHeroTokenId;
        this.contract = args.contract;
	}
}

export class ApiHeroesContractTokenIdGetArgs {
	contract?: string;
	tokenId?: string;

	constructor(args: ApiHeroesContractTokenIdGetArgs) {
        this.contract = args.contract;
        this.tokenId = args.tokenId;
	}
}

export class ApiHeroesContractTokenIdMinPriceGetArgs {
	contract?: string;
	tokenId?: string;

	constructor(args: ApiHeroesContractTokenIdMinPriceGetArgs) {
        this.contract = args.contract;
        this.tokenId = args.tokenId;
	}
}

export class ApiHeroesContractTokenIdRefreshPostArgs {
	contract?: string;
	tokenId?: string;

	constructor(args: ApiHeroesContractTokenIdRefreshPostArgs) {
        this.contract = args.contract;
        this.tokenId = args.tokenId;
	}
}

export class ApiHeroesGetArgs {
	owner?: string;
	limit?: number;
	offset?: number;
	raceType?: string;
	classType?: string;
	rankType?: string;
	rarityCounter?: string;
	orderBy?: string;
	desc?: any;
	lockStatus?: string;
	saleType?: string;
	manaCosts?: string;
	searchTroopsKills?: string;

	constructor(args: ApiHeroesGetArgs) {
        this.owner = args.owner;
        this.limit = args.limit;
        this.offset = args.offset;
        this.raceType = args.raceType;
        this.classType = args.classType;
        this.rankType = args.rankType;
        this.rarityCounter = args.rarityCounter;
        this.orderBy = args.orderBy;
        this.desc = args.desc;
        this.lockStatus = args.lockStatus;
        this.saleType = args.saleType;
        this.manaCosts = args.manaCosts;
        this.searchTroopsKills = args.searchTroopsKills;
	}
}

export class ApiHeroesContractTokenIdSellPostArgs {
	contract?: string;
	tokenId?: string;

	constructor(args: ApiHeroesContractTokenIdSellPostArgs) {
        this.contract = args.contract;
        this.tokenId = args.tokenId;
	}
}

export class ApiHeroesContractTokenIdSaleGetArgs {
	contract?: string;
	tokenId?: string;

	constructor(args: ApiHeroesContractTokenIdSaleGetArgs) {
        this.contract = args.contract;
        this.tokenId = args.tokenId;
	}
}

export class ApiHeroesContractSyncUserAddressPostArgs {
	address?: string;

	constructor(args: ApiHeroesContractSyncUserAddressPostArgs) {
        this.address = args.address;
	}
}

export class ApiHeroesSalesSaleIdBuyPostArgs {
	saleId?: string;

	constructor(args: ApiHeroesSalesSaleIdBuyPostArgs) {
        this.saleId = args.saleId;
	}
}

export class ApiHeroesSalesSaleIdDeleteArgs {
	saleId?: string;

	constructor(args: ApiHeroesSalesSaleIdDeleteArgs) {
        this.saleId = args.saleId;
	}
}

export class ApiHistoriesOwnerAddrGetArgs {
	ownerAddr?: string;
	eventType?: string;

	constructor(args: ApiHistoriesOwnerAddrGetArgs) {
        this.ownerAddr = args.ownerAddr;
        this.eventType = args.eventType;
	}
}

export class ApiUsersAddrGetArgs {
	addr?: string;

	constructor(args: ApiUsersAddrGetArgs) {
        this.addr = args.addr;
	}
}

export class ApiUsersAddrPutArgs {
	addr?: string;

	constructor(args: ApiUsersAddrPutArgs) {
        this.addr = args.addr;
	}
}

export class ApiUsersAddrStatisticGetArgs {
	addr?: string;

	constructor(args: ApiUsersAddrStatisticGetArgs) {
        this.addr = args.addr;
	}
}

export class ApiUsersAddrRefStatisticGetArgs {
	addr?: string;

	constructor(args: ApiUsersAddrRefStatisticGetArgs) {
        this.addr = args.addr;
	}
}

export class ApiScholarsSubAccsGetArgs {
	address?: string;
	hasScholarship?: any;

	constructor(args: ApiScholarsSubAccsGetArgs) {
        this.address = args.address;
        this.hasScholarship = args.hasScholarship;
	}
}

export class ApiScholarsScholarshipsGetArgs {
	currentPage?: number;
	pageSize?: number;
	sortBy?: string;
	orderBy?: string;
	full?: any;
	scholarshipIds?: string;
	scholarshipWeb3Address?: string;
	web3Address?: string;
	scholarshipWeb3AddressAssigned?: any;
	isScholarshipActive?: any;
	scholarshipStatus?: string;

	constructor(args: ApiScholarsScholarshipsGetArgs) {
        this.currentPage = args.currentPage;
        this.pageSize = args.pageSize;
        this.sortBy = args.sortBy;
        this.orderBy = args.orderBy;
        this.full = args.full;
        this.scholarshipIds = args.scholarshipIds;
        this.scholarshipWeb3Address = args.scholarshipWeb3Address;
        this.web3Address = args.web3Address;
        this.scholarshipWeb3AddressAssigned = args.scholarshipWeb3AddressAssigned;
        this.isScholarshipActive = args.isScholarshipActive;
        this.scholarshipStatus = args.scholarshipStatus;
	}
}

export class ApiScholarsScholarshipsScholarStatusGetArgs {
	scholarAddr?: string;
	scholarshipIds?: string;

	constructor(args: ApiScholarsScholarshipsScholarStatusGetArgs) {
        this.scholarAddr = args.scholarAddr;
        this.scholarshipIds = args.scholarshipIds;
	}
}

export class ApiScholarsScholarshipsScholarshipStatusGetArgs {
	scholarshipIds?: string;

	constructor(args: ApiScholarsScholarshipsScholarshipStatusGetArgs) {
        this.scholarshipIds = args.scholarshipIds;
	}
}

export class ApiScholarsScholarshipsApplicationsGetArgs {
	owner?: string;
	limit?: number;
	offset?: number;

	constructor(args: ApiScholarsScholarshipsApplicationsGetArgs) {
        this.owner = args.owner;
        this.limit = args.limit;
        this.offset = args.offset;
	}
}

export class ApiScholarsScholarshipsScholarshipIdGetArgs {
	scholarshipId?: string;

	constructor(args: ApiScholarsScholarshipsScholarshipIdGetArgs) {
        this.scholarshipId = args.scholarshipId;
	}
}

export class ApiScholarsScholarshipsScholarshipIdPutArgs {
	scholarshipId?: string;

	constructor(args: ApiScholarsScholarshipsScholarshipIdPutArgs) {
        this.scholarshipId = args.scholarshipId;
	}
}

export class ApiScholarsScholarshipsApplicationsIdApprovePostArgs {
	id?: string;

	constructor(args: ApiScholarsScholarshipsApplicationsIdApprovePostArgs) {
        this.id = args.id;
	}
}

export class ApiScholarsScholarshipsApplicationsIdRejectPostArgs {
	id?: string;

	constructor(args: ApiScholarsScholarshipsApplicationsIdRejectPostArgs) {
        this.id = args.id;
	}
}

export class ApiScholarsScholarshipsApplicationsIdSelfCancelPostArgs {
	id?: string;

	constructor(args: ApiScholarsScholarshipsApplicationsIdSelfCancelPostArgs) {
        this.id = args.id;
	}
}

export class ApiScholarsScholarshipsScholarshipIdApplyPostArgs {
	scholarshipId?: string;

	constructor(args: ApiScholarsScholarshipsScholarshipIdApplyPostArgs) {
        this.scholarshipId = args.scholarshipId;
	}
}

export class ApiScholarsScholarshipsScholarshipIdCancelPostArgs {
	scholarshipId?: string;

	constructor(args: ApiScholarsScholarshipsScholarshipIdCancelPostArgs) {
        this.scholarshipId = args.scholarshipId;
	}
}

export class ApiScholarsScholarshipsScholarshipIdGerneratePasswordPostArgs {
	scholarshipId?: string;

	constructor(args: ApiScholarsScholarshipsScholarshipIdGerneratePasswordPostArgs) {
        this.scholarshipId = args.scholarshipId;
	}
}

export class ApiScholarsScholarshipsScholarshipIdCreatingSignatureGetArgs {
	scholarshipId?: string;

	constructor(args: ApiScholarsScholarshipsScholarshipIdCreatingSignatureGetArgs) {
        this.scholarshipId = args.scholarshipId;
	}
}

export class ApiScholarsScholarshipsScholarshipIdUnlockSignatureGetArgs {
	scholarshipId?: string;

	constructor(args: ApiScholarsScholarshipsScholarshipIdUnlockSignatureGetArgs) {
        this.scholarshipId = args.scholarshipId;
	}
}

export class ApiScholarsScholarshipsScholarshipIdConfirmUnlockedPostArgs {
	scholarshipId?: string;

	constructor(args: ApiScholarsScholarshipsScholarshipIdConfirmUnlockedPostArgs) {
        this.scholarshipId = args.scholarshipId;
	}
}

export class ApiScholarsScholarshipsScholarshipIdActivePostArgs {
	scholarshipId?: string;

	constructor(args: ApiScholarsScholarshipsScholarshipIdActivePostArgs) {
        this.scholarshipId = args.scholarshipId;
	}
}

export class ApiWithdrawalDepositTokenBalanceGetArgs {
	token?: string;

	constructor(args: ApiWithdrawalDepositTokenBalanceGetArgs) {
        this.token = args.token;
	}
}

export class ApiWithdrawalDepositTokenWithdrawalPostArgs {
	token?: string;

	constructor(args: ApiWithdrawalDepositTokenWithdrawalPostArgs) {
        this.token = args.token;
	}
}

export class ApiWithdrawalDepositTokenDepositPostArgs {
	token?: string;

	constructor(args: ApiWithdrawalDepositTokenDepositPostArgs) {
        this.token = args.token;
	}
}

export class ApiWithdrawalDepositTokenDepositIdPutArgs {
	id?: string;
	token?: string;

	constructor(args: ApiWithdrawalDepositTokenDepositIdPutArgs) {
        this.id = args.id;
        this.token = args.token;
	}
}

export class ApiWithdrawalDepositTokenDepositIdDeleteArgs {
	id?: string;
	token?: string;

	constructor(args: ApiWithdrawalDepositTokenDepositIdDeleteArgs) {
        this.id = args.id;
        this.token = args.token;
	}
}

export class ApiWithdrawalDepositHistoriesGetArgs {
	limit?: number;
	offset?: number;
	keyword?: string;
	sort?: string[];
	from?: string;
	to?: string;
	type?: string;
	status?: string;
	token?: string;

	constructor(args: ApiWithdrawalDepositHistoriesGetArgs) {
        this.limit = args.limit;
        this.offset = args.offset;
        this.keyword = args.keyword;
        this.sort = args.sort;
        this.from = args.from;
        this.to = args.to;
        this.type = args.type;
        this.status = args.status;
        this.token = args.token;
	}
}

export class ApiAdminWithdrawalDepositHistoriesGetArgs {
	limit?: number;
	offset?: number;
	keyword?: string;
	sort?: string[];
	from?: string;
	to?: string;
	type?: string;
	status?: string;
	token?: string;

	constructor(args: ApiAdminWithdrawalDepositHistoriesGetArgs) {
        this.limit = args.limit;
        this.offset = args.offset;
        this.keyword = args.keyword;
        this.sort = args.sort;
        this.from = args.from;
        this.to = args.to;
        this.type = args.type;
        this.status = args.status;
        this.token = args.token;
	}
}

export class ApiAdminWithdrawalDepositIdApproveTxHashPutArgs {
	id?: string;
	txHash?: string;
	token?: string;

	constructor(args: ApiAdminWithdrawalDepositIdApproveTxHashPutArgs) {
        this.id = args.id;
        this.txHash = args.txHash;
        this.token = args.token;
	}
}

export class ApiAdminWithdrawalDepositIdRejectPutArgs {
	id?: string;
	token?: string;

	constructor(args: ApiAdminWithdrawalDepositIdRejectPutArgs) {
        this.id = args.id;
        this.token = args.token;
	}
}

export class ApiAdminWithdrawalDepositIdRevertPutArgs {
	id?: string;
	token?: string;

	constructor(args: ApiAdminWithdrawalDepositIdRevertPutArgs) {
        this.id = args.id;
        this.token = args.token;
	}
}

export class ApiAdminWithdrawalDepositHistoriesExportGetArgs {
	keyword?: string;
	sort?: string[];
	from?: string;
	to?: string;
	type?: string;
	status?: string;
	token?: string;

	constructor(args: ApiAdminWithdrawalDepositHistoriesExportGetArgs) {
        this.keyword = args.keyword;
        this.sort = args.sort;
        this.from = args.from;
        this.to = args.to;
        this.type = args.type;
        this.status = args.status;
        this.token = args.token;
	}
}

export class ApiAdminRedeemRedeemHistoriesGetArgs {
	limit?: number;
	offset?: number;
	keyword?: string;
	sort?: string[];
	from?: string;
	to?: string;
	status?: string;

	constructor(args: ApiAdminRedeemRedeemHistoriesGetArgs) {
        this.limit = args.limit;
        this.offset = args.offset;
        this.keyword = args.keyword;
        this.sort = args.sort;
        this.from = args.from;
        this.to = args.to;
        this.status = args.status;
	}
}

export class ApiAdminRedeemCheckInHistoriesGetArgs {
	limit?: number;
	offset?: number;
	keyword?: string;
	sort?: string[];
	from?: string;
	to?: string;

	constructor(args: ApiAdminRedeemCheckInHistoriesGetArgs) {
        this.limit = args.limit;
        this.offset = args.offset;
        this.keyword = args.keyword;
        this.sort = args.sort;
        this.from = args.from;
        this.to = args.to;
	}
}

export class ApiAdminRedeemIdApproveTxHashPutArgs {
	id?: string;
	txHash?: string;

	constructor(args: ApiAdminRedeemIdApproveTxHashPutArgs) {
        this.id = args.id;
        this.txHash = args.txHash;
	}
}

export class ApiAdminRedeemIdRejectPutArgs {
	id?: string;

	constructor(args: ApiAdminRedeemIdRejectPutArgs) {
        this.id = args.id;
	}
}

export class ApiAdminRedeemIdRevertPutArgs {
	id?: string;

	constructor(args: ApiAdminRedeemIdRevertPutArgs) {
        this.id = args.id;
	}
}

export class ApiAdminRedeemHistoriesExportGetArgs {
	keyword?: string;
	sort?: string[];
	from?: string;
	to?: string;
	status?: string;

	constructor(args: ApiAdminRedeemHistoriesExportGetArgs) {
        this.keyword = args.keyword;
        this.sort = args.sort;
        this.from = args.from;
        this.to = args.to;
        this.status = args.status;
	}
}

export class ApiCheckInRedeemHistoriesGetArgs {
	limit?: number;
	offset?: number;
	keyword?: string;
	sort?: string[];
	from?: string;
	to?: string;
	status?: string;

	constructor(args: ApiCheckInRedeemHistoriesGetArgs) {
        this.limit = args.limit;
        this.offset = args.offset;
        this.keyword = args.keyword;
        this.sort = args.sort;
        this.from = args.from;
        this.to = args.to;
        this.status = args.status;
	}
}

export class AuthenticationApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiAuthLoginPost(body?: LoginDTO, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/auth/login';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAuthRegisterPost(body?: RegisterDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/auth/register';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAuthNonceAddrGet(args: ApiAuthNonceAddrGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { addr } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/auth/nonce/{addr}';
		url = url.split(['{', '}'].join('addr')).join(encodeURIComponent(String(addr)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAuthForgotPasswordPost(body?: ForgotPasswordDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/auth/forgot-password';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAuthResetPasswordPost(body?: ResetPasswordDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/auth/reset-password';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAuthChangePasswordPost(body?: ChangePasswordDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/auth/change-password';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAuthOtpPost(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/auth/otp';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminAuthNonceAddrGet(args: ApiAdminAuthNonceAddrGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { addr } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/auth/nonce/{addr}';
		url = url.split(['{', '}'].join('addr')).join(encodeURIComponent(String(addr)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminAuthLoginPost(body?: LoginDTO, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/auth/login';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getAuthenticationApi = (accessToken?: string) => getApiService('AuthenticationApi', AuthenticationApi, accessToken);

export class CocoonsApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiCocoonsContractTokenIdGet(args: ApiCocoonsContractTokenIdGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { contract, tokenId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/cocoons/{contract}/{tokenId}';
		url = url.split(['{', '}'].join('contract')).join(encodeURIComponent(String(contract)));
		url = url.split(['{', '}'].join('tokenId')).join(encodeURIComponent(String(tokenId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCocoonsGet(args: ApiCocoonsGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { owner, limit, offset, cocoonTypes, orderBy, desc, saleType } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/cocoons';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (owner !== undefined) {
            params.append('owner', owner as any);
        }
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (offset !== undefined) {
            params.append('offset', offset as any);
        }
        if (cocoonTypes !== undefined) {
            params.append('cocoonTypes', cocoonTypes as any);
        }
        if (orderBy !== undefined) {
            params.append('orderBy', orderBy as any);
        }
        if (desc !== undefined) {
            params.append('desc', desc as any);
        }
        if (saleType !== undefined) {
            params.append('saleType', saleType as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCocoonsOpenPost(body?: OpenCocoonDTO, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/cocoons/open';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCocoonsContractTokenIdRefreshPost(args: ApiCocoonsContractTokenIdRefreshPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { contract, tokenId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/cocoons/{contract}/{tokenId}/refresh';
		url = url.split(['{', '}'].join('contract')).join(encodeURIComponent(String(contract)));
		url = url.split(['{', '}'].join('tokenId')).join(encodeURIComponent(String(tokenId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCocoonsContractTokenIdSellPost(args: ApiCocoonsContractTokenIdSellPostArgs, body?: SellInput, options: MethodOptions | any = {}): Promise<any> { 
        const { contract, tokenId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/cocoons/{contract}/{tokenId}/sell';
		url = url.split(['{', '}'].join('contract')).join(encodeURIComponent(String(contract)));
		url = url.split(['{', '}'].join('tokenId')).join(encodeURIComponent(String(tokenId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCocoonsSalesSaleIdBuyPost(args: ApiCocoonsSalesSaleIdBuyPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { saleId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/cocoons/sales/{saleId}/buy';
		url = url.split(['{', '}'].join('saleId')).join(encodeURIComponent(String(saleId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCocoonsSalesSaleIdDelete(args: ApiCocoonsSalesSaleIdDeleteArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { saleId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/cocoons/sales/{saleId}';
		url = url.split(['{', '}'].join('saleId')).join(encodeURIComponent(String(saleId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'delete',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCocoonsContractTokenIdSaleGet(args: ApiCocoonsContractTokenIdSaleGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { contract, tokenId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/cocoons/{contract}/{tokenId}/sale';
		url = url.split(['{', '}'].join('contract')).join(encodeURIComponent(String(contract)));
		url = url.split(['{', '}'].join('tokenId')).join(encodeURIComponent(String(tokenId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCocoonsContractSyncUserAddressPost(args: ApiCocoonsContractSyncUserAddressPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { address } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/cocoons/{contract}/sync/user/{address}';
		url = url.split(['{', '}'].join('address')).join(encodeURIComponent(String(address)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getCocoonsApi = (accessToken?: string) => getApiService('CocoonsApi', CocoonsApi, accessToken);

export class HealthApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiHealthGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/health';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then(response => resolve(response as any));
            } else {
                promise.then(response => {
                    if (response.status === 200 || response.status === 204) {
                        return response.json();
                    } else {
                        reject(response);
                    }
                }).then(data => resolve(data));
            }
			promise.catch(error => reject(error));
		});
	}

	apiHealthVersionGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/health/version';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getHealthApi = (accessToken?: string) => getApiService('HealthApi', HealthApi, accessToken);

export class HeroesApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiHeroesBreedEstimateGet(args: ApiHeroesBreedEstimateGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { firstHeroTokenId, secondHeroTokenId, contract } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/heroes/breed/estimate';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (firstHeroTokenId !== undefined) {
            params.append('firstHeroTokenId', firstHeroTokenId as any);
        }
        if (secondHeroTokenId !== undefined) {
            params.append('secondHeroTokenId', secondHeroTokenId as any);
        }
        if (contract !== undefined) {
            params.append('contract', contract as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiHeroesBreedPost(body?: EstimateBreedingDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/heroes/breed';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiHeroesContractTokenIdGet(args: ApiHeroesContractTokenIdGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { contract, tokenId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/heroes/{contract}/{tokenId}';
		url = url.split(['{', '}'].join('contract')).join(encodeURIComponent(String(contract)));
		url = url.split(['{', '}'].join('tokenId')).join(encodeURIComponent(String(tokenId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiHeroesContractTokenIdMinPriceGet(args: ApiHeroesContractTokenIdMinPriceGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { contract, tokenId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/heroes/{contract}/{tokenId}/min-price';
		url = url.split(['{', '}'].join('contract')).join(encodeURIComponent(String(contract)));
		url = url.split(['{', '}'].join('tokenId')).join(encodeURIComponent(String(tokenId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiHeroesContractTokenIdRefreshPost(args: ApiHeroesContractTokenIdRefreshPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { contract, tokenId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/heroes/{contract}/{tokenId}/refresh';
		url = url.split(['{', '}'].join('contract')).join(encodeURIComponent(String(contract)));
		url = url.split(['{', '}'].join('tokenId')).join(encodeURIComponent(String(tokenId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiHeroesGet(args: ApiHeroesGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { owner, limit, offset, raceType, classType, rankType, rarityCounter, orderBy, desc, lockStatus, saleType, manaCosts, searchTroopsKills } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/heroes';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (owner !== undefined) {
            params.append('owner', owner as any);
        }
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (offset !== undefined) {
            params.append('offset', offset as any);
        }
        if (raceType !== undefined) {
            params.append('raceType', raceType as any);
        }
        if (classType !== undefined) {
            params.append('classType', classType as any);
        }
        if (rankType !== undefined) {
            params.append('rankType', rankType as any);
        }
        if (rarityCounter !== undefined) {
            params.append('rarityCounter', rarityCounter as any);
        }
        if (orderBy !== undefined) {
            params.append('orderBy', orderBy as any);
        }
        if (desc !== undefined) {
            params.append('desc', desc as any);
        }
        if (lockStatus !== undefined) {
            params.append('lockStatus', lockStatus as any);
        }
        if (saleType !== undefined) {
            params.append('saleType', saleType as any);
        }
        if (manaCosts !== undefined) {
            params.append('manaCosts', manaCosts as any);
        }
        if (searchTroopsKills !== undefined) {
            params.append('searchTroopsKills', searchTroopsKills as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiHeroesContractTokenIdSellPost(args: ApiHeroesContractTokenIdSellPostArgs, body?: SellInput, options: MethodOptions | any = {}): Promise<any> { 
        const { contract, tokenId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/heroes/{contract}/{tokenId}/sell';
		url = url.split(['{', '}'].join('contract')).join(encodeURIComponent(String(contract)));
		url = url.split(['{', '}'].join('tokenId')).join(encodeURIComponent(String(tokenId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiHeroesContractTokenIdSaleGet(args: ApiHeroesContractTokenIdSaleGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { contract, tokenId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/heroes/{contract}/{tokenId}/sale';
		url = url.split(['{', '}'].join('contract')).join(encodeURIComponent(String(contract)));
		url = url.split(['{', '}'].join('tokenId')).join(encodeURIComponent(String(tokenId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiHeroesContractSyncUserAddressPost(args: ApiHeroesContractSyncUserAddressPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { address } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/heroes/{contract}/sync/user/{address}';
		url = url.split(['{', '}'].join('address')).join(encodeURIComponent(String(address)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiHeroesSalesSaleIdBuyPost(args: ApiHeroesSalesSaleIdBuyPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { saleId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/heroes/sales/{saleId}/buy';
		url = url.split(['{', '}'].join('saleId')).join(encodeURIComponent(String(saleId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiHeroesSalesSaleIdDelete(args: ApiHeroesSalesSaleIdDeleteArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { saleId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/heroes/sales/{saleId}';
		url = url.split(['{', '}'].join('saleId')).join(encodeURIComponent(String(saleId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'delete',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getHeroesApi = (accessToken?: string) => getApiService('HeroesApi', HeroesApi, accessToken);

export class HistoriesApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiHistoriesOwnerAddrGet(args: ApiHistoriesOwnerAddrGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { ownerAddr, eventType } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/histories/{ownerAddr}';
		url = url.split(['{', '}'].join('ownerAddr')).join(encodeURIComponent(String(ownerAddr)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (eventType !== undefined) {
            params.append('eventType', eventType as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getHistoriesApi = (accessToken?: string) => getApiService('HistoriesApi', HistoriesApi, accessToken);

export class UserApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiUsersAddrGet(args: ApiUsersAddrGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { addr } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/users/{addr}';
		url = url.split(['{', '}'].join('addr')).join(encodeURIComponent(String(addr)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiUsersAddrPut(args: ApiUsersAddrPutArgs, body?: UpdateUserInfoDTO, options: MethodOptions | any = {}): Promise<any> { 
        const { addr } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/users/{addr}';
		url = url.split(['{', '}'].join('addr')).join(encodeURIComponent(String(addr)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiUsersAddrStatisticGet(args: ApiUsersAddrStatisticGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { addr } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/users/{addr}/statistic';
		url = url.split(['{', '}'].join('addr')).join(encodeURIComponent(String(addr)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiUsersAddrRefStatisticGet(args: ApiUsersAddrRefStatisticGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { addr } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/users/{addr}/ref-statistic';
		url = url.split(['{', '}'].join('addr')).join(encodeURIComponent(String(addr)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getUserApi = (accessToken?: string) => getApiService('UserApi', UserApi, accessToken);

export class SystemsApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiSystemsHeroesClassesGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/systems/heroes/classes';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiSystemsHeroesRacesGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/systems/heroes/races';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiSystemsHeroesRanksGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/systems/heroes/ranks';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiSystemsHistoriesEventsGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/systems/histories/events';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getSystemsApi = (accessToken?: string) => getApiService('SystemsApi', SystemsApi, accessToken);

export class ScholarsApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiScholarsSubAccsPost(body?: CreateScholarSubAccDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/sub-accs';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsSubAccsGet(args: ApiScholarsSubAccsGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { address, hasScholarship } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/sub-accs';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (address !== undefined) {
            params.append('address', address as any);
        }
        if (hasScholarship !== undefined) {
            params.append('hasScholarship', hasScholarship as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsPost(body?: CreateScholarshipDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsGet(args: ApiScholarsScholarshipsGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { currentPage, pageSize, sortBy, orderBy, full, scholarshipIds, scholarshipWeb3Address, web3Address, scholarshipWeb3AddressAssigned, isScholarshipActive, scholarshipStatus } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (currentPage !== undefined) {
            params.append('currentPage', currentPage as any);
        }
        if (pageSize !== undefined) {
            params.append('pageSize', pageSize as any);
        }
        if (sortBy !== undefined) {
            params.append('sortBy', sortBy as any);
        }
        if (orderBy !== undefined) {
            params.append('orderBy', orderBy as any);
        }
        if (full !== undefined) {
            params.append('full', full as any);
        }
        if (scholarshipIds !== undefined) {
            params.append('scholarshipIds', scholarshipIds as any);
        }
        if (scholarshipWeb3Address !== undefined) {
            params.append('scholarshipWeb3Address', scholarshipWeb3Address as any);
        }
        if (web3Address !== undefined) {
            params.append('web3Address', web3Address as any);
        }
        if (scholarshipWeb3AddressAssigned !== undefined) {
            params.append('scholarshipWeb3AddressAssigned', scholarshipWeb3AddressAssigned as any);
        }
        if (isScholarshipActive !== undefined) {
            params.append('isScholarshipActive', isScholarshipActive as any);
        }
        if (scholarshipStatus !== undefined) {
            params.append('scholarshipStatus', scholarshipStatus as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsScholarStatusGet(args: ApiScholarsScholarshipsScholarStatusGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { scholarAddr, scholarshipIds } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/scholar-status';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (scholarAddr !== undefined) {
            params.append('scholarAddr', scholarAddr as any);
        }
        if (scholarshipIds !== undefined) {
            params.append('scholarshipIds', scholarshipIds as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsClaimPost(body?: ClaimScholarshipDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/claim';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsPayoutPost(body?: PayoutScholarshipDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/payout';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsScholarshipStatusGet(args: ApiScholarsScholarshipsScholarshipStatusGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { scholarshipIds } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/scholarship-status';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (scholarshipIds !== undefined) {
            params.append('scholarshipIds', scholarshipIds as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsApplicationsGet(args: ApiScholarsScholarshipsApplicationsGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { owner, limit, offset } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/applications';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (owner !== undefined) {
            params.append('owner', owner as any);
        }
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (offset !== undefined) {
            params.append('offset', offset as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsScholarshipIdGet(args: ApiScholarsScholarshipsScholarshipIdGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { scholarshipId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/{scholarshipId}';
		url = url.split(['{', '}'].join('scholarshipId')).join(encodeURIComponent(String(scholarshipId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsScholarshipIdPut(args: ApiScholarsScholarshipsScholarshipIdPutArgs, body?: UpdateScholarshipDto, options: MethodOptions | any = {}): Promise<any> { 
        const { scholarshipId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/{scholarshipId}';
		url = url.split(['{', '}'].join('scholarshipId')).join(encodeURIComponent(String(scholarshipId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsApplicationsIdApprovePost(args: ApiScholarsScholarshipsApplicationsIdApprovePostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/applications/{id}/approve';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsApplicationsIdRejectPost(args: ApiScholarsScholarshipsApplicationsIdRejectPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/applications/{id}/reject';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsApplicationsIdSelfCancelPost(args: ApiScholarsScholarshipsApplicationsIdSelfCancelPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/applications/{id}/self-cancel';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsScholarshipIdApplyPost(args: ApiScholarsScholarshipsScholarshipIdApplyPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { scholarshipId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/{scholarshipId}/apply';
		url = url.split(['{', '}'].join('scholarshipId')).join(encodeURIComponent(String(scholarshipId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsScholarshipIdCancelPost(args: ApiScholarsScholarshipsScholarshipIdCancelPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { scholarshipId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/{scholarshipId}/cancel';
		url = url.split(['{', '}'].join('scholarshipId')).join(encodeURIComponent(String(scholarshipId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsScholarshipIdGerneratePasswordPost(args: ApiScholarsScholarshipsScholarshipIdGerneratePasswordPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { scholarshipId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/{scholarshipId}/gernerate-password';
		url = url.split(['{', '}'].join('scholarshipId')).join(encodeURIComponent(String(scholarshipId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsScholarshipIdCreatingSignatureGet(args: ApiScholarsScholarshipsScholarshipIdCreatingSignatureGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { scholarshipId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/{scholarshipId}/creating-signature';
		url = url.split(['{', '}'].join('scholarshipId')).join(encodeURIComponent(String(scholarshipId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsScholarshipIdUnlockSignatureGet(args: ApiScholarsScholarshipsScholarshipIdUnlockSignatureGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { scholarshipId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/{scholarshipId}/unlock-signature';
		url = url.split(['{', '}'].join('scholarshipId')).join(encodeURIComponent(String(scholarshipId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsScholarshipIdConfirmUnlockedPost(args: ApiScholarsScholarshipsScholarshipIdConfirmUnlockedPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { scholarshipId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/{scholarshipId}/confirm-unlocked';
		url = url.split(['{', '}'].join('scholarshipId')).join(encodeURIComponent(String(scholarshipId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsScholarshipIdActivePost(args: ApiScholarsScholarshipsScholarshipIdActivePostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { scholarshipId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/{scholarshipId}/active';
		url = url.split(['{', '}'].join('scholarshipId')).join(encodeURIComponent(String(scholarshipId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsAssetsTransferPost(body?: TransferAssetToSubAccInput, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/assets/transfer';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiScholarsScholarshipsAssetsOverviewGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/scholars/scholarships/assets/overview';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getScholarsApi = (accessToken?: string) => getApiService('ScholarsApi', ScholarsApi, accessToken);

export class WithdrawalDepositApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiWithdrawalDepositTokenBalanceGet(args: ApiWithdrawalDepositTokenBalanceGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { token } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/withdrawal-deposit/{token}/balance';
		url = url.split(['{', '}'].join('token')).join(encodeURIComponent(String(token)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiWithdrawalDepositTokenWithdrawalPost(args: ApiWithdrawalDepositTokenWithdrawalPostArgs, body?: WithdrawalRequestDto, options: MethodOptions | any = {}): Promise<any> { 
        const { token } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/withdrawal-deposit/{token}/withdrawal';
		url = url.split(['{', '}'].join('token')).join(encodeURIComponent(String(token)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiWithdrawalDepositTokenDepositPost(args: ApiWithdrawalDepositTokenDepositPostArgs, body?: DepositRequestDto, options: MethodOptions | any = {}): Promise<any> { 
        const { token } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/withdrawal-deposit/{token}/deposit';
		url = url.split(['{', '}'].join('token')).join(encodeURIComponent(String(token)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiWithdrawalDepositTokenDepositIdPut(args: ApiWithdrawalDepositTokenDepositIdPutArgs, body?: ConfirmDepositRequestDto, options: MethodOptions | any = {}): Promise<any> { 
        const { id, token } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/withdrawal-deposit/{token}/deposit/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
		url = url.split(['{', '}'].join('token')).join(encodeURIComponent(String(token)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiWithdrawalDepositTokenDepositIdDelete(args: ApiWithdrawalDepositTokenDepositIdDeleteArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id, token } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/withdrawal-deposit/{token}/deposit/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
		url = url.split(['{', '}'].join('token')).join(encodeURIComponent(String(token)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'delete',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiWithdrawalDepositHistoriesGet(args: ApiWithdrawalDepositHistoriesGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { limit, offset, keyword, sort, from, to, type, status, token } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/withdrawal-deposit/histories';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (offset !== undefined) {
            params.append('offset', offset as any);
        }
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (sort !== undefined) {
            (sort || []).forEach(item => {
                params.append('sort', item as any);
            });
        }
        if (from !== undefined) {
            params.append('from', from as any);
        }
        if (to !== undefined) {
            params.append('to', to as any);
        }
        if (type !== undefined) {
            params.append('type', type as any);
        }
        if (status !== undefined) {
            params.append('status', status as any);
        }
        if (token !== undefined) {
            params.append('token', token as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getWithdrawalDepositApi = (accessToken?: string) => getApiService('WithdrawalDepositApi', WithdrawalDepositApi, accessToken);

export class AdminWithdrawalDepositApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiAdminWithdrawalDepositHistoriesGet(args: ApiAdminWithdrawalDepositHistoriesGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { limit, offset, keyword, sort, from, to, type, status, token } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/withdrawal-deposit/histories';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (offset !== undefined) {
            params.append('offset', offset as any);
        }
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (sort !== undefined) {
            (sort || []).forEach(item => {
                params.append('sort', item as any);
            });
        }
        if (from !== undefined) {
            params.append('from', from as any);
        }
        if (to !== undefined) {
            params.append('to', to as any);
        }
        if (type !== undefined) {
            params.append('type', type as any);
        }
        if (status !== undefined) {
            params.append('status', status as any);
        }
        if (token !== undefined) {
            params.append('token', token as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminWithdrawalDepositIdApproveTxHashPut(args: ApiAdminWithdrawalDepositIdApproveTxHashPutArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id, txHash, token } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/withdrawal-deposit/{id}/approve/{txHash}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
		url = url.split(['{', '}'].join('txHash')).join(encodeURIComponent(String(txHash)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (token !== undefined) {
            params.append('token', token as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminWithdrawalDepositIdRejectPut(args: ApiAdminWithdrawalDepositIdRejectPutArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id, token } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/withdrawal-deposit/{id}/reject';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (token !== undefined) {
            params.append('token', token as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminWithdrawalDepositIdRevertPut(args: ApiAdminWithdrawalDepositIdRevertPutArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id, token } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/withdrawal-deposit/{id}/revert';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (token !== undefined) {
            params.append('token', token as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminWithdrawalDepositHistoriesExportGet(args: ApiAdminWithdrawalDepositHistoriesExportGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { keyword, sort, from, to, type, status, token } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/withdrawal-deposit/histories/export';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (sort !== undefined) {
            (sort || []).forEach(item => {
                params.append('sort', item as any);
            });
        }
        if (from !== undefined) {
            params.append('from', from as any);
        }
        if (to !== undefined) {
            params.append('to', to as any);
        }
        if (type !== undefined) {
            params.append('type', type as any);
        }
        if (status !== undefined) {
            params.append('status', status as any);
        }
        if (token !== undefined) {
            params.append('token', token as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getAdminWithdrawalDepositApi = (accessToken?: string) => getApiService('AdminWithdrawalDepositApi', AdminWithdrawalDepositApi, accessToken);

export class AdminRedeemControllerApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiAdminRedeemCheckInManyPost(body?: CheckInManyDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/redeem/check-in-many';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminRedeemRedeemHistoriesGet(args: ApiAdminRedeemRedeemHistoriesGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { limit, offset, keyword, sort, from, to, status } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/redeem/redeem/histories';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (offset !== undefined) {
            params.append('offset', offset as any);
        }
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (sort !== undefined) {
            (sort || []).forEach(item => {
                params.append('sort', item as any);
            });
        }
        if (from !== undefined) {
            params.append('from', from as any);
        }
        if (to !== undefined) {
            params.append('to', to as any);
        }
        if (status !== undefined) {
            params.append('status', status as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminRedeemCheckInHistoriesGet(args: ApiAdminRedeemCheckInHistoriesGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { limit, offset, keyword, sort, from, to } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/redeem/check-in/histories';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (offset !== undefined) {
            params.append('offset', offset as any);
        }
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (sort !== undefined) {
            (sort || []).forEach(item => {
                params.append('sort', item as any);
            });
        }
        if (from !== undefined) {
            params.append('from', from as any);
        }
        if (to !== undefined) {
            params.append('to', to as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminRedeemIdApproveTxHashPut(args: ApiAdminRedeemIdApproveTxHashPutArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id, txHash } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/redeem/{id}/approve/{txHash}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
		url = url.split(['{', '}'].join('txHash')).join(encodeURIComponent(String(txHash)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminRedeemIdRejectPut(args: ApiAdminRedeemIdRejectPutArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/redeem/{id}/reject';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminRedeemIdRevertPut(args: ApiAdminRedeemIdRevertPutArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/redeem/{id}/revert';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminRedeemHistoriesExportGet(args: ApiAdminRedeemHistoriesExportGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { keyword, sort, from, to, status } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/redeem/histories/export';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (sort !== undefined) {
            (sort || []).forEach(item => {
                params.append('sort', item as any);
            });
        }
        if (from !== undefined) {
            params.append('from', from as any);
        }
        if (to !== undefined) {
            params.append('to', to as any);
        }
        if (status !== undefined) {
            params.append('status', status as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getAdminRedeemControllerApi = (accessToken?: string) => getApiService('AdminRedeemControllerApi', AdminRedeemControllerApi, accessToken);

export class CheckInApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiCheckInRedeemCheckInPost(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/check-in-redeem/check-in';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCheckInRedeemRedeemPost(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/check-in-redeem/redeem';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCheckInRedeemStatusGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/check-in-redeem/status';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCheckInRedeemHistoriesGet(args: ApiCheckInRedeemHistoriesGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { limit, offset, keyword, sort, from, to, status } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/check-in-redeem/histories';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (offset !== undefined) {
            params.append('offset', offset as any);
        }
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (sort !== undefined) {
            (sort || []).forEach(item => {
                params.append('sort', item as any);
            });
        }
        if (from !== undefined) {
            params.append('from', from as any);
        }
        if (to !== undefined) {
            params.append('to', to as any);
        }
        if (status !== undefined) {
            params.append('status', status as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getCheckInApi = (accessToken?: string) => getApiService('CheckInApi', CheckInApi, accessToken);
