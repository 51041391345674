import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from "./store";
import { DefaultFcProps } from "common";

export const withRedux = (Component: React.FC<DefaultFcProps>) => (props: DefaultFcProps) => (
  <ReduxProvider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}
    >
      <Component {...props} />
    </PersistGate>
  </ReduxProvider>
);
