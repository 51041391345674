import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';

import { MenuItem as MenuItemModel } from '../../helpers';

import './user-information.scss';
import { selectAddress, signOut } from 'redux-reducers';
import { formatAddress } from 'utils';

const menuItemNames = {
  logout: 'logout'
}

const menuItems: MenuItemModel[] = [
  {
    id: 1,
    name: menuItemNames.logout,
    text: 'Logout',
  },
];

export const UserInformation = () => {
  const dispatch = useDispatch()
  const address = useSelector(selectAddress)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleToggleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorElUser(anchorElUser ? null : (event.target as HTMLElement).closest('.dropdown') as HTMLElement);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onSelectMenu = (menuItem: MenuItemModel) => async () => {
    handleCloseUserMenu();
    if (menuItem.name === menuItemNames.logout) {
      dispatch(signOut());
    }
  };

  return (
    <div className='flex items-center gap-6'>
      <Box sx={{ flexGrow: 0 }}>
        <div
          onClick={handleToggleUserMenu}
          className="flex gap-2 cursor-pointer dropdown"
        >
          <img src="/assets/images/avatar.svg" width={40} alt="user avatar" />
          <div className='flex flex-col items-start'>
            <div className='flex items-center gap-2'>
              <span className='text-sm text-[#17204D] '>{formatAddress(address)}</span><Icon path={mdiChevronDown} size={1} color="#17204D" />
            </div>
            <div className='text-xs text-[#7A7F9A]'>admin</div>
          </div>
        </div>
        {anchorElUser && (
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {menuItems.map((menuItem) => (
              <MenuItem
                key={menuItem.id}

                onClick={onSelectMenu(menuItem)}
              >
                <div
                  className="user-information--menu-item flex gap-2 w-full"
                >
                  {menuItem.text}
                </div>
              </MenuItem>
            ))}
          </Menu>
        )}
      </Box>
    </div>
  )
}