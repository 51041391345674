import { signIn, store } from 'redux-reducers';
import { connectAddress, setAccessToken, toJson, isNotNilAndEmpty } from 'utils';
import { web3 } from 'contracts';
import { getAuthenticationApi } from 'swagger';

const connect = async (address: string): Promise<{ nonce: string }> => {
  const {
    data
  } = await getAuthenticationApi()
    .apiAdminAuthNonceAddrGet({
      addr: address,
    })
    .then(toJson)

  return data;
}

const login = async (data: {
  addr: string,
  signature: string,
}): Promise<string> => {
  const {
    data: {
      token
    },
  } = await getAuthenticationApi()
    .apiAdminAuthLoginPost({
      addr: data.addr,
      signedStr: data.signature
    })
    .then(toJson)
  return token
}

export const connectWallet = async (callback?: () => void) => {
  try {
    const addr = await connectAddress();
    if (!addr) return;

    const { isLoggedIn } = store.getState().user;
    if (isLoggedIn) {
      if (callback) callback();
    } else {
      const { nonce } = await connect(addr);
      // eslint-disable-next-line
      const message = `Welcome to OKG Marketplace!\n\nClick \"Sign\" to sign in. No password needed!\n\nWallet address:\n${addr}\n\nNonce:\n${nonce}`;
      const password = '' //No password needed
      const rawSig = await web3.eth.personal.sign(message, addr, password);
      const splitAt = rawSig.length - 2;

      // for Ethereum, last bytes of the signature must be 1b or 1c
      // Metamask did this automatically but for hardware wallet like Trezor or Ledger,
      // this must be done manually
      let v = rawSig.slice(-2);
      if (v === '00') {
        v = '1b';
      } else if (v === '01') {
        v = '1c';
      }
      const signature = rawSig.substring(0, splitAt) + v;

      const token = await login({
        addr,
        signature,
      });

      if (isNotNilAndEmpty(token)) {
        setAccessToken(token)
        store.dispatch(
          signIn({ address: addr, token })
        );

        if (callback) callback();
      }
    }
  } catch (err: any) {
    if (err.json) {
      const data = await toJson(err as any);
      alert(data.message);
    } else {
      console.log('connectWallet', err);
    }
  }
};
