import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'redux-reducers';

export const useLoggedInGuard = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if (isLoggedIn) return

    document.location.href = "/login";
  }, [isLoggedIn])
}